import React, { useState } from 'react';
import hamsterImage from '../assets/otchim.jpg'; // замените на ваше изображение

function Home() {
  const [coins, setCoins] = useState(507981);

  // Функция обработки клика
  const handleImageClick = () => {
    // Например, увеличиваем количество монет на 12 за каждый клик
    setCoins(coins + 12);
  };

  return (
    <div className="home-container">
      <h2>Hamster Kombat</h2>
      <div className="hamster-circle" onClick={handleImageClick}>
        <img src={hamsterImage} alt="Hamster" className="hamster-img" />
      </div>
      <div className="stats-container">
        <div className="stat-item">Earn per tap: +12</div>
        <div className="stat-item">Coins to level up: 10M</div>
        <div className="stat-item">Profit per hour: +636.31K</div>
      </div>
      <div className="coins-display">
        <h1>{coins.toLocaleString()}</h1>
      </div>
      <div className="progress-bar">
        <div className="progress-level">Epic</div>
        <div className="progress-fill" style={{ width: '70%' }}></div>
      </div>
    </div>
  );
}

export default Home;
