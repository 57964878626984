import React from 'react';

function Stats() {
  return (
    <div className="stats-container">
      <h2>Daily combo</h2>
      <div className="combo-box">
        <p>+5,000,000</p>
        <div className="combo-items">
          <div className="combo-item">License Japan</div>
          <div className="combo-item">QA Team</div>
          <div className="combo-item">Meme coins</div>
        </div>
      </div>
      <div className="extra-stats">
        <div className="extra-stat">Markets</div>
        <div className="extra-stat">PR & Team</div>
        <div className="extra-stat">Legal</div>
        <div className="extra-stat">Specials</div>
      </div>
    </div>
  );
}

export default Stats;
