import React from 'react';

function SpecialOffers() {
  return (
    <div className="special-offers-container">
      <h2>Top 10 cmc pairs</h2>
      <div className="offer-box">
        <p>Most traded cryptocurrency pairs market capitalization</p>
        <div className="offer-earnings">
          <span>Profit per hour: +123</span>
        </div>
        <div className="offer-total">
          <h1>123,981</h1>
        </div>
        <button className="cta-button">Go ahead</button>
      </div>
    </div>
  );
}

export default SpecialOffers;
