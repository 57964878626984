import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Leaderboard.css'; // Опционально, если вы добавите стили

function Leaderboard() {
  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    axios.get('/api/leaderboard') // Замените URL на ваш реальный URL для получения данных
      .then(response => setLeaders(response.data))
      .catch(error => console.error('Error fetching leaderboard data:', error));
  }, []);

  return (
    <div className="leaderboard-container">
      <h2>Leaderboard</h2>
      <ul>
        {leaders.map((leader, index) => (
          <li key={index}>
            <span>{index + 1}. {leader.name}</span>
            <span>{leader.coins.toLocaleString()} Coins</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Leaderboard;
