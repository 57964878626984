import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Stats from './components/Stats';
import SpecialOffers from './components/SpecialOffers';
import Leaderboard from './components/Leaderboard'; // Импортируйте компонент

function App() {
  return (
    <Router>
      <div className="app-container">
        <nav className="nav-bar">
          <Link to="/" className="nav-link">Home</Link>
          <Link to="/stats" className="nav-link">Stats</Link>
          <Link to="/special-offers" className="nav-link">Special Offers</Link>
          <Link to="/leaderboard" className="nav-link">Leaderboard</Link> {/* Добавьте ссылку */}
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/special-offers" element={<SpecialOffers />} />
          <Route path="/leaderboard" element={<Leaderboard />} /> {/* Добавьте маршрут */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
